<template>
  <div class="wrapper" style="padding: 10px 20px">
    <div class="top">
      <div class="title">
        {{
          house.rentType === 1
            ? '整租 · ' + house.projectName
            : '分租 · ' + house.projectName
        }}
      </div>
      <div class="tags" v-if="tags.length > 0">
        <el-tag :type="item.type" v-for="item in tags" :key="item"
          >{{ item.text }}
        </el-tag>
      </div>
      <div class="context">
        <div class="left">
          <el-carousel
            arrow="always"
            height="430px"
            :autoplay="autoplay"
            :interval="interval"
            @change="onChange"
          >
            <el-carousel-item v-for="item in imgages" :key="item">
              <!-- <vue3VideoPlay
                v-bind="playOption"
                @play="onPlay"
                @pause="onPause"
                @canplay="onCanplay"
                v-if="item.src.includes('.mp4')"
              />
              <img
                v-else
                style="width: 100%; height: 100%"
                :src="item.src"
                alt=""
              /> -->
              <img style="width: 100%; height: 100%" :src="item.src" alt="" />
            </el-carousel-item>
          </el-carousel>
          <div class="tip">
            温馨提示：房源图片真实性由发布方负责，平台对此不承担任何责任
          </div>
        </div>
        <div class="right">
          <div class="sale-wrapper">
            <div class="total">
              {{ numberFormat(house.rentPrice, 2, '.', ',') }}
              <div class="unit">元/㎡</div>
            </div>
          </div>
          <div class="fields-wrapper">
            <div class="field-item">
              <div class="value">
                {{ house.roomNum }}室{{ house.hallNum }}厅{{
                  house.toiletNum
                }}卫
              </div>
              <div class="label">户型</div>
            </div>
            <div class="field-item">
              <div class="value">{{ house.buildingArea }}㎡</div>
              <div class="label">建筑面积</div>
            </div>
            <div class="field-item">
              <div class="value">
                {{
                  house.situationName
                    ? house.situationName
                    : situationsMap.find(
                        (item) => item.value == house.situation
                      )
                    ? situationsMap.find(
                        (item) => item.value == house.situation
                      ).label
                    : '--'
                }}
              </div>
              <div class="label">装修类型</div>
            </div>
          </div>
          <div
            style="
              display: flex;
              align-items: flex-start;
              justify-content: space-between;
            "
          >
            <div>
              <div class="tips-wrapper">
                <div class="tip-item">
                  <div class="label">所属区域：</div>
                  <div class="value">
                    {{ house.districtName }} {{ house.street }}
                  </div>
                </div>
                <div class="tip-item">
                  <div class="label">所在楼层：</div>
                  <!-- <div class="value">起第{{ house.startFloor }}层，止第{{ house.terminationFloor }}层/总{{ house.buildingTotalFloor }}层</div> -->
                  <div class="value">
                    第{{ house.startFloor }}层/总{{
                      house.buildingTotalFloor
                    }}层
                  </div>
                </div>
                <div class="tip-item">
                  <div class="label">房屋用途：</div>
                  <div class="value">{{ house.housingUse }}</div>
                </div>
                <div class="tip-item">
                  <div class="label">房屋朝向：</div>
                  <div class="value">{{ house.towardName }}</div>
                </div>
                <div class="tip-item">
                  <div class="label">挂牌时间：</div>
                  <div class="value">
                    {{ !!house.upAt ? house.upAt.split(' ')[0] : '' }}
                  </div>
                </div>
                <div class="tip-item">
                  <div class="label">房源核验码：</div>
                  <div class="value">{{ house.verificationCode }}</div>
                </div>
              </div>
            </div>
            <div
              style="
                height: 200px;
                width: 200px;
                display: flex;
                align-items: center;
              "
            >
              <qrcode-vue
                :value="house.codePath"
                :size="180"
                level="H"
                :foreground="
                  house.handleCertificate == 1 ? '#008000' : '#409eff'
                "
              />
            </div>
          </div>
          <div class="btn-action">
            <el-button type="primary" size="small" @click="JumpToAppointment(1)"
              >预约看房</el-button
            >
            <el-button type="primary" size="small" @click="JumpToAppointment(2)"
              >预约签约</el-button
            >
          </div>
          <div class="prompt-wrapper">
            <div class="prompt-title">
              <i class="el-icon-warning-outline mr-2"></i>产权核验：
            </div>
            <div class="content">
              <p>
                本平台仅对房源存在的真实性进行核验，请平台用户自行判断是否接受机构服务，平台对此不承担任何责任
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="center">
      <div class="left">
        <nesi-section :title="`配套设施`">
          <div class="install-wrapper">
            <div
              class="install-item"
              v-for="item in constant.showInstall"
              :key="item"
            >
              <i
                class="cust-i custom-icon"
                :style="{ color: item.color }"
                :class="item.icon"
              ></i>
              <div class="label" :class="{ check: item.isCheck }">
                {{ item.text }}
              </div>
            </div>
          </div>
        </nesi-section>
        <nesi-section :title="`房东要求`">
          <div class="tags" v-if="landlordRequestTags.length > 0">
            <el-tag
              :type="item.type"
              v-for="item in landlordRequestTags"
              :key="item"
              >{{ item.text }}
            </el-tag>
          </div>
        </nesi-section>
        <nesi-section :title="'发布人信息'">
          <div class="link-wrapper" v-if="!isProxy">
            <nesi-link-man
              v-if="hasItem"
              :userId="house.userId"
              @callPhone="callPhone"
              :userItem="userItem"
            >
            </nesi-link-man>
          </div>
          <div v-else class="link-wrapper">
            <nesi-link-man
              v-for="item in proxyUser"
              :userId="item.userId"
              :key="item.id"
              @callPhone="callPhone"
              :userItem="item"
            ></nesi-link-man>
          </div>
        </nesi-section>
        <nesi-section :title="'房源描述'">
          <div style="width: 83%; text-align: justify">
            {{ house.title }}
          </div>
        </nesi-section>
      </div>
      <div class="right">
        <nesi-section :title="`最新房源推荐`">
          <nesi-house-small-item
            v-for="item in originData"
            :key="item"
            :item="item"
            :minio="minio"
            @click="toDetail(item)"
          ></nesi-house-small-item>
        </nesi-section>
      </div>
    </div>
    <div class="bottom">
      <nesi-section :title="`周边环境`">
        <div class="map" id="map"></div>
        <div class="query-content">
          <el-tabs
            v-model="activeName"
            type="border-card"
            @tab-click="handleClick"
            class="tabs"
          >
            <el-tab-pane label="公交" name="traffic">
              <div class="search-item" v-for="item in searchList" :key="item">
                <div class="context">{{ item.title }}</div>
                <div class="dis">{{ item.dis + 'km' }}</div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="教育" name="education">
              <div class="search-item" v-for="item in searchList" :key="item">
                <div class="context">{{ item.title }}</div>
                <div class="dis">{{ item.dis + 'km' }}</div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="医疗" name="treatment">
              <div class="search-item" v-for="item in searchList" :key="item">
                <div class="context">{{ item.title }}</div>
                <div class="dis">{{ item.dis + 'km' }}</div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="购物" name="shopping">
              <div class="search-item" v-for="item in searchList" :key="item">
                <div class="context">{{ item.title }}</div>
                <div class="dis">{{ item.dis + 'km' }}</div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="银行" name="life">
              <div class="search-item" v-for="item in searchList" :key="item">
                <div class="context">{{ item.title }}</div>
                <div class="dis">{{ item.dis + 'km' }}</div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="娱乐" name="entertainment">
              <div class="search-item" v-for="item in searchList" :key="item">
                <div class="context">{{ item.title }}</div>
                <div class="dis">{{ item.dis + 'km' }}</div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </nesi-section>
    </div>

    <div class="tip-wrapper">
      <div class="title">
        <i class="el-icon-warning-outline mr-2"></i>风险提示信息：
      </div>
      <div class="content">
        <p>1. 本平台挂牌的拟租价格仅供参考，实际成交价格由租赁双方自行商定。</p>
        <p>
          2.
          本平台发布的房屋图片由经纪机构、租赁企业或出租人提供，仅供参考，承租前请务必实地查看房屋真实情况。
        </p>
        <p>
          3.
          签订租赁合同时，租赁双方当事人应当出示有效身份证明，出租人应当向承租人出示房屋所有权证明或其他核发权属证明的原件。
        </p>
        <p>
          4.
          本平台挂牌房屋成交后应按规定签订《南宁市房屋租赁合同》，办理租赁备案手续，非本市户籍的承租人同时应按规定办理居住证，所需资料及办理条件以法律法规规定及主管部门的要求为准。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import NesiLinkMan from '@/components/NesiLinkMan/index.vue'
import NesiSection from '@/components/NesiSection/index.vue'
import NesiHouseSmallItem from '@/components/NesiHouseSmallItem/index.vue'
import { useRouter, useRoute } from 'vue-router'
import { deepCopy, numberFormat } from '@/utils/util'
import { getHouseById, newHouseList } from '@/api/home'
import { INSTALL_MAP, SITUATIONS_MAP } from '@/constant'
import { defineComponent, ref, reactive, computed } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useStore } from 'vuex'
import QrcodeVue from 'qrcode.vue'
import vue3VideoPlay from 'vue3-video-play'
import 'vue3-video-play/dist/style.css'

export default defineComponent({
  components: {
    NesiSection,
    NesiLinkMan,
    NesiHouseSmallItem,
    QrcodeVue,
    vue3VideoPlay
  },
  name: 'detail',
  // props: {
  //   title
  // },
  setup() {
    const activeName = ref('traffic')
    const route = useRoute()
    const router = useRouter()
    const isProxy = ref(false)
    const situationsMap = ref(deepCopy(SITUATIONS_MAP))
    const constant = reactive({
      installs: deepCopy(INSTALL_MAP),
      showInstall: []
    })
    const originData = ref([])
    const id = ref(route.query.id)
    const house = ref({})
    const callPhone = async (userId) => {
      const phoneInfo = '请手机拨打联系人号码：' + house.value.rentMobile
      ElMessageBox.alert(phoneInfo, '联系电话', {
        confirmButtonText: '确定'
      })
    }
    const handlerInstalls = (installStr) => {
      if (installStr) {
        const list = installStr.split(',')
        if (list.length) {
          constant.showInstall = constant.installs.map((install) => {
            list.map((item) => {
              if (install.text === item) {
                install.isCheck = true
                install.color = '#303133'
              }
            })
            return install
          })
        }
      }
    }

    const videoPlayer = ref()

    // vue3-video-play  播放器默认的设置
    const playOption = ref({
      width: '100%', // 播放器高度
      height: '100%', // 播放器高度
      color: '#409eff', // 主题色
      title: '', // 视频名称
      src: '', // 视频源
      muted: false, // 静音
      webFullScreen: false,
      speedRate: ['0.75', '1.0', '1.25', '1.5', '2.0'], // 播放倍速
      autoPlay: false, // 自动播放
      loop: false, // 循环播放
      mirror: false, // 镜像画面
      ligthOff: true, // 关灯模式
      volume: 0.3, // 默认音量大小
      control: true, // 是否显示控制
      controlBtns: [
        'audioTrack',
        'quality',
        'speedRate',
        'volume',
        'setting',
        'pip',
        'pageFullScreen',
        'fullScreen'
      ] // 显示所有按钮,
    })

    const onPlay = (ev) => {
      interval.value = 1.5 * 1000
      autoplay.value = false
      console.log('播放')
    }
    const onPause = (ev) => {
      console.log(ev, '暂停')
    }
    const onCanplay = (ev) => {
      console.log(ev, '可以播放')
    }
    const interval = ref(1500)
    const autoplay = ref(true)
    const onChange = (index) => {
      // 如果是视频,就等播放完再进行下一个
      if (imgages.value[index].src.indexOf('mp4') != -1) {
        playOption.value.src = imgages.value[index].src // 把获取来的视频赋值给sources的src
        interval.value = 1.5 * 1000
        autoplay.value = false
      }
    }

    const userItem = ref({})
    const hasItem = ref(false)
    const tags = ref([])
    const landlordRequestTags = ref([])
    const minio = ref('')
    const types = ['', 'success', 'info', 'warning', 'danger']
    let circle = {}
    let map = {}
    let point = {}
    const searchList = ref([])
    const createMap = (latitude, longitude) => {
      const win = window
      const BMap = win.BMap
      map = new BMap.Map('map')
      const lat = latitude || 22.823889
      const lng = longitude || 108.372645
      point = new BMap.Point(lng, lat)
      map.centerAndZoom(point, 15) // 设置缩放级别
      // const myIcon = new BMap.Icon('../../assets/map-house-icon.png', new BMap.Size(32, 32))
      const marker = new BMap.Marker(point) // 创建点
      map.addOverlay(marker) // 增加点
      circle = new BMap.Circle(point, 1000, {
        fillColor: 'blue',
        strokeWeight: 1,
        fillOpacity: 0.3,
        strokeOpacity: 0.3
      })

      map.enableScrollWheelZoom(true) // 开启鼠标滚轮缩放
      const scaleCtrl = new BMap.ScaleControl() // 添加比例尺控件
      map.addControl(scaleCtrl)

      const opts = {
        width: 200, // 信息窗口宽度
        height: 100, // 信息窗口高度
        title: '故宫博物院', // 信息窗口标题
        message: '这里是故宫'
      }
      const infoWindow = new BMap.InfoWindow(
        '地址：北京市东城区王府井大街88号乐天银泰百货八层',
        opts
      ) // 创建信息窗口对象
      marker.addEventListener('click', () => {
        map.openInfoWindow(infoWindow, point) // 开启信息窗口
      })
      return map
    }
    // 最新房源
    const queryList = async () => {
      const res = await newHouseList({ type: 1, houseType: 3 })
      if (res.records.records.length > 4) {
        originData.value = res.records.records.slice(0, 4)
      } else {
        originData.value = res.records.records
      }
      minio.value = res.minioUrl
    }

    const toDetail = (item) => {
      router.push({ name: 'houseDetail', query: { id: item.houseId } })
    }
    const store = useStore()
    const user = computed(() => {
      return store.getters.userInfo
    })

    // 根据球面坐标获得平面坐标。
    const getMecator = (poi) => {
      return map.getMapType().getProjection().lngLatToPoint(poi)
    }
    // 根据平面坐标获得球面坐标。
    const getPoi = (mecator) => {
      return map.getMapType().getProjection().pointToLngLat(mecator)
    }

    const queryPRJ = (name, longitude, latitude) => {
      const win = window
      // const opts = {
      //   position: new win.BMap.Point(longitude, latitude), // 指定文本标注所在的地理位置
      //   offset: new win.BMap.Size(-40, -60) // 设置文本偏移量
      // }
      // // 创建文本标注对象
      // const label = new win.BMap.Label(name, opts)
      // // 自定义文本标注样式
      // label.setStyle({
      //   color: 'white',
      //   borderRadius: '5px',
      //   borderColor: '#e4e7ed',
      //   padding: '2px 10px',
      //   fontSize: '16px',
      //   height: '30px',
      //   lineHeight: '30px',
      //   fontFamily: '微软雅黑',
      //   background: '#ff9900'
      // })
      const opts1 = {
        width: 40, // 信息窗口宽度
        height: 30, // 信息窗口高度
        title: '' // 信息窗口标题
      }
      const infoWindow = new win.BMap.InfoWindow(name, opts1) // 创建信息窗口对象
      map.openInfoWindow(infoWindow, new win.BMap.Point(longitude, latitude))
      // map.addOverlay(label)
    }

    const getSquareBounds = (centerPoi, r) => {
      const win = window
      const a = Math.sqrt(2) * r // 正方形边长
      const mPoi = getMecator(centerPoi)
      const x0 = mPoi.x
      const y0 = mPoi.y
      const x1 = x0 + a / 2
      const y1 = y0 + a / 2 // 东北点
      const x2 = x0 - a / 2
      const y2 = y0 - a / 2 // 西南点
      const ne = getPoi(new win.BMap.Pixel(x1, y1))
      const sw = getPoi(new win.BMap.Pixel(x2, y2))
      return new win.BMap.Bounds(sw, ne)
    }

    const searchByKey = (key) => {
      map.clearOverlays()
      searchList.value = []
      // 定义搜索关键词 (多关键词搜索)
      const myKeys = [key]
      // 检索结束后的回调函数
      const options = {
        pageCapacity: 9,
        renderOptions: { map: map },
        onSearchComplete: function (results) {
          const length = results[0].getNumPois()
          for (let i = 0; i < length; i++) {
            let title = ''
            let disPoint = ''
            if (
              results[0].getPoi(i) &&
              results[0].getPoi(i).title &&
              results[0].getPoi(i).point
            ) {
              title = results[0].getPoi(i).title
              disPoint = results[0].getPoi(i).point
              const dis = (map.getDistance(point, disPoint) / 1000).toFixed(2)
              const option = { title, dis }
              searchList.value.push(option)
            }
          }
        }
      }
      // renderOptions: 搜索结果, 地图于map实例显示，列表在 r-result显示
      const win = window
      const local = new win.BMap.LocalSearch(map, options)
      local.disableFirstResultSelection()
      const bounds = getSquareBounds(circle.getCenter(), circle.getRadius())
      local.searchInBounds(myKeys, bounds)
    }
    const longitude = ref(0)
    const latitude = ref(0)
    const proName = ref('')
    const getLocation = async (keyValue, name) => {
      if (keyValue) {
        map = createMap(house.value.latitude, house.value.longitude)
        searchByKey('公交')
        longitude.value = house.value.longitude
        latitude.value = house.value.latitude
        proName.value = house.value.projectName
        queryPRJ(
          house.value.projectName,
          house.value.longitude,
          house.value.latitude
        )
      }
    }

    const proxyUser = ref([])
    const getProxyUser = async (houseId) => {
      const params = { houseId }
      console.log(params)
      // const res = await axios.get(URL.houseApi.selectHouseAgent, { params })
      // if (res.code === 200) {
      //   if (res.result) {
      //     const list = res.result.list
      //     if (list && list.length) {
      //       proxyUser.value = list.map(_item => {
      //         return {
      //           name: _item.name,
      //           pName: res.result.entName,
      //           phoneNo: _item.mobile,
      //           id: _item.employId,
      //           userId: _item.userId
      //         }
      //       })
      //     }
      //   }
      // } else {
      //   ElMessage({ type: 'error', message: '获取代理员工数据失败' })
      // }
    }
    const imgages = ref([])
    const getDetail = async (houseId) => {
      const res = await getHouseById({ houseId })
      const item = res
      house.value = res
      console.log('=====')

      console.log(house.value)
      minio.value = res.minioUrl
      if (item.type === 17) {
        isProxy.value = true
        getProxyUser(item.houseId)
      }
      handlerInstalls(item.collocationName)
      userItem.value = {
        avatar: item.minioUrl + item.headImg,
        realname: item.rentName,
        enterpriseId: item.enterpriseId,
        enterpriseName: item.enterpriseName,
        companyStaff: item.companyStaff
      }
      hasItem.value = true
      if (item.houseTags) {
        tags.value = item.houseTags.split(',').map((item, index) => {
          return {
            text: item,
            type: types[index % 5]
          }
        })
      }
      if (item.landlordRequest) {
        landlordRequestTags.value = item.landlordRequest
          .split(',')
          .map((item, index) => {
            return {
              text: item,
              type: types[index % 5]
            }
          })
      }
      imgages.value = JSON.parse(item.houseOther).map((item) => {
        let path = ''
        if (item.scanData) path = item.scanData
        else if (item.smjsj) path = item.smjsj
        else path = item.src
        return { src: minio.value + path }
      })

      // imgages.value.push({
      //   src: 'https://pc.nnfwzl.com/rent-house/rent-platform-web/test.mp4'
      // })

      getLocation(item.communityId, item.projectName)
    }
    if (house.value.hosueTags) {
      tags.value = house.value.hosueTags.split(',').map((item, index) => {
        return {
          text: item,
          type: types[index % 5]
        }
      })
    }

    const handleClick = (val) => {
      searchByKey(val.props.label)
      queryPRJ(proName.value, longitude.value, latitude.value)
    }
    // 预约看房跳转
    const JumpToAppointment = (appointmentType) => {
      const houseInfo = house.value
      const routerUrl = router.resolve({
        path: '/user/appointment',
        query: {
          fwid: houseInfo.houseId,
          appointmentType: appointmentType,
          czqsrq: houseInfo.lesseeBeginTime,
          czzzrq: houseInfo.lesseeEndTime
        }
      })
      window.open(routerUrl.href, '_blank')
    }

    getDetail(id.value)
    queryList()
    return {
      hasItem,
      userItem,
      callPhone,
      activeName,
      searchList,
      house,
      imgages,
      tags,
      landlordRequestTags,
      constant,
      originData,
      minio,
      toDetail,
      handleClick,
      isProxy,
      proxyUser,
      situationsMap,
      numberFormat,
      JumpToAppointment,
      playOption,
      onPlay,
      onPause,
      onCanplay,
      onChange,
      interval,
      autoplay
    }
  }
})
</script>

<style scoped lang="scss">
.wrapper {
  width: 100%;
  height: 100%;

  .tags {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .el-tag {
      margin-right: 20px;
    }

    .label {
      color: #909399;
      font-size: 14px;
      width: 85px;
      text-align: right;
    }
  }
  .top {
    .title {
      font-size: 20px;
      font-weight: 800;
      height: 40px;
      line-height: 40px;
      margin-bottom: 10px;
    }

    .context {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .left {
        width: 51%;
        position: relative;

        .tip {
          position: absolute;
          left: 0;
          bottom: 0;
          z-index: 1000;
          background: rgba(0, 0, 0, 0.3);
          color: white;
          height: 40px;
          line-height: 40px;
          width: 100%;
          text-align: center;
          font-size: 14px;
        }
      }

      .right {
        flex: 1;
        height: 430px;
        padding-left: 15px;
        display: flex;
        flex-direction: column;

        .sale-wrapper {
          display: flex;
          align-items: center;
          height: 40px;

          .total {
            font-size: 30px;
            font-weight: 800;
            color: #fa3534;
            display: flex;
            align-items: center;

            .unit {
              font-weight: 100;
              font-size: 22px;
              margin-left: 3px;
              margin-top: 2px;
            }
          }

          .price {
            margin-left: 10px;
          }
        }

        .btn-action {
          /* display: flex;
           justify-content: center;*/
        }

        .fields-wrapper {
          display: flex;
          border-bottom: 1px solid #e4e7ed;
          border-top: 1px solid #e4e7ed;
          padding: 10px 0;

          .field-item {
            width: 33.3%;
            display: flex;
            align-items: center;
            flex-direction: column;
            border-left: 1px solid #e4e7ed;

            &:last-child {
              border-right: 1px solid #e4e7ed;
            }

            .value {
              font-size: 24px;
            }

            .label {
              margin-top: 5px;
              color: #909399;
            }
          }
        }

        .tips-wrapper {
          margin: 5px 0;
          display: flex;
          align-items: flex-start;
          flex-direction: column;

          .tip-item {
            display: flex;
            height: 27px;
            line-height: 27px;

            .label {
              color: #909399;
              font-size: 14px;
              width: 85px;
              text-align: right;
            }

            .value {
              font-size: 14px;
            }
          }
        }
        .prompt-wrapper {
          margin-top: 5px;
          flex: 1;
          color: #ff9900;
          background: #fdf6ec;
          border-radius: 5px;
          padding: 5px 5px 0;

          .prompt-title {
            font-size: 14px;
          }

          .content {
            font-size: 13px;

            p {
              margin-top: 5px;
              height: 20px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }

  .center {
    display: flex;
    margin-top: 5px;

    .left {
      flex: 1;
      display: flex;
      flex-direction: column;

      ::v-deep .section-wrapper {
        padding: 0;
        height: auto;

        .content {
          margin-bottom: 0;
        }
      }

      .install-wrapper {
        display: flex;
        flex-wrap: wrap;

        .install-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 80px;
          margin-bottom: 20px;

          .icon {
            font-size: 50px;
          }

          .label {
            color: #909399;
            text-decoration: line-through;

            &.check {
              color: #303133;
              text-decoration: none;
            }
          }
        }
      }

      .link-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }

    .right {
      width: 416px;

      ::v-deep .section-wrapper {
        padding: 0;
        height: auto;

        .content {
          padding: 0;
          border: solid 1px #e4e7ed;
          border-top: none;
        }
      }
    }
  }

  .bottom {
    ::v-deep .section-wrapper {
      padding: 0;
      height: auto;

      .content {
        padding: 0;
        position: relative;

        .map {
          height: 500px;
          width: 100%;
        }

        .query-content {
          width: 416px;
          max-height: 460px;
          background: white;
          z-index: 1000;
          position: absolute;
          right: 20px;
          top: 20px;

          .search-item {
            height: 40px;
            border-bottom: solid 1px #e4e7ed;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            margin: 0 15px;

            &:hover {
              background: #f3f4f6;
            }

            .context {
              flex: 1;
            }

            .dis {
              width: 15%;
              margin-left: 8px;
            }
          }

          .tabs {
            // padding: 0 20px;
          }
        }
      }
    }
  }

  .tip-wrapper {
    color: #ff9900;
    background: #fdf6ec;
    padding: 10px;
    border-radius: 5px;

    .title {
      font-size: 14px;
    }

    .content {
      margin-top: 5px;
      // padding-left: 10px;
      font-size: 13px;

      p {
        height: 20px;
        line-height: 20px;
      }
    }
  }
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 400px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

::v-deep .el-carousel__indicators--horizontal {
  bottom: 35px;
}

.cust-i {
  height: 40px;
  width: 50px;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

::v-deep .el-tabs--border-card > .el-tabs__content {
  padding: 15px 0;
}
</style>
