<template>
  <div class="link-man-wrapper">
    <div class="left">
      <el-image class="image" :src="userItem.avatar">
        <template #error>
          <el-image
            class="image"
            src="http://img0.baidu.com/it/u=1580287918,1662928404&fm=253&app=138&f=JPEG?w=500&h=586"
          >
          </el-image>
        </template>
      </el-image>
    </div>
    <div class="center">
      <div v-if="!userItem.enterpriseId">
        {{ userItem.realname }}
      </div>
      <div v-else>
        <el-link
          :underline="false"
          @click="staffDetail"
          type="primary"
          style="font-size: 18px"
          target="_blank"
          >{{ username }}</el-link
        >
      </div>
      <div
        style="
          font-size: 14px;
          display: flex;
          align-items: center;
          padding-top: 5px;
        "
      >
        评分：
        <el-rate v-model="value" disabled text-color="#ff9900" />
      </div>
      <div
        class="tip"
        v-if="!userItem.enterpriseId"
        style="font-size: 14px; display: flex"
      >
        <div style="width: 42px">来源：</div>
        <div>个人发布</div>
      </div>
      <div class="tip" v-else style="font-size: 14px; display: flex">
        <div style="width: 50px">公司：</div>
        <el-link
          :underline="false"
          target="_blank"
          @click="entDetail"
          type="primary"
          >{{ userItem.enterpriseName }}</el-link
        >
      </div>
    </div>
    <el-button class="right" type="primary" plain @click="callPhone"
      >电话咨询</el-button
    >
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { getVirtualPhoneApi } from '@/api/home'
import { useRouter } from 'vue-router'
import { ElMessageBox } from 'element-plus'
export default defineComponent({
  name: 'nesi-link-man',
  props: {
    userItem: {
      type: Object
    },
    userId: {
      type: String
    },
    realName: {
      type: String
    }
  },

  setup(props, context) {
    const router = useRouter()
    let companyStaff = null
    if (props.userItem.companyStaff && props.userItem.companyStaff.length > 0) {
      companyStaff = props.userItem.companyStaff.find(
        (item) => item.userId === props.userId
      )
    }
    const username = ref('')
    if (companyStaff) {
      username.value = companyStaff.userName
    } else {
      username.value = props.realName
    }

    const entDetail = (fname) => {
      router.push({
        path: `/enterpriseInfo/detail/${props.userItem.enterpriseName}`
      })
    }
    const staffDetail = (fname) => {
      router.push({
        path: `/enterpriseInfo/agent/${companyStaff.employeeId}`
      })
    }

    const callPhone = async (item) => {
      const result = await getVirtualPhoneApi(props.userId)
      ElMessageBox.alert(`请手机拨打联系人号码：${result}`, '拨打提示', {
        confirmButtonText: '确认',
        callback: (action) => {}
      })
    }
    const value = ref(5)
    return {
      callPhone,
      username,
      entDetail,
      staffDetail,
      value
    }
  }
})
</script>

<style scoped lang="scss">
.link-man-wrapper {
  width: 49%;
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  border: solid 1px #e4e7ed;
  padding: 10px 8px;
  border-radius: 4px;
  .left {
    margin-right: 10px;
    display: flex;
    align-items: center;
    .image {
      width: 60px;
      height: 60px;
      border-radius: 30px;
    }
  }
  .center {
    width: 60%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    font-size: 20px;
    flex-direction: column;
    padding-right: 5px;
    .tip {
      font-size: 14px;
      font-weight: 100;
      margin-top: 8px;
    }
  }
  .right {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    height: 40%;
    padding: 10px !important;
  }
}
</style>
