<template>
  <div class="small-wrapper">
    <div class="item">
      <div class="left">
        <el-image
          class="image"
          :src="imgageSrc"
          referrer="no-referrer|origin|unsafe-url"
        >
          <template #error>
            <div class="image-slot" style="height: 100%">
              <div
                style="
                  width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  opacity: 0.8;
                  background: #ecf5ff;
                  flex-direction: column;
                  color: #5298f5;
                  font-size: 12px;
                  padding-top: 25px;
                "
              >
                <el-image
                  class="image"
                  style="width: 70px; height: 50px"
                  src="https://pc.nnfwzl.com/rent-house/rent-platform-web/default.png"
                />
                图片正在拍摄中
              </div>
            </div>
          </template>
        </el-image>
        <el-image
          v-if="thirdSource"
          style="
            position: absolute;
            right: 0;
            top: 0;
            height: 70px;
            width: 70px;
          "
          :src="thirdSource"
          referrer="no-referrer|origin|unsafe-url"
        >
        </el-image>
        <div class="validate">
          {{
            house.govVerification && house.govVerification !== '0'
              ? '产权核验'
              : '未核验'
          }}
        </div>
      </div>
      <div class="center">
        <div class="title">
          {{ house.projectName }} · {{ house.buildingArea }}㎡ ·
          {{ house.roomNum }}室{{ house.hallNum }}厅{{ house.toiletNum }}卫
        </div>
        <div class="tips-wrapper">
          <div class="tips">
            {{ house.towardName }} | {{ house.buildingArea }}㎡ |
            {{
              constant.situationList.find(
                (item) => item.value === house.situation
              )
                ? constant.situationList.find(
                    (item) => item.value === house.situation
                  ).label
                : '--'
            }}
            | {{ house.upAt.split(' ')[0] }}
          </div>
          <div class="tips">
            {{
              constant.areaList.find(
                (item) => item.value === house.areaId.toString()
              )
                ? constant.areaList.find(
                    (item) => item.value === house.areaId.toString()
                  ).label
                : '--'
            }}
            | {{ house.street }} |
            {{ house.projectName }}
          </div>
          <div class="tips">
            统一编码：{{ house.areaId + house.houseNumber }}
          </div>
        </div>
        <div class="sale">
          <div class="total">
            {{ numberFormat(item.rentPrice, 2, '.', ',') }} 元/月
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, defineComponent, reactive, computed } from 'vue'
import { numberFormat, deepCopy, getSource } from '@/utils/util'
import { AREA_MAP, SITUATIONS_MAP } from '@/constant'
import { useStore } from 'vuex'
export default defineComponent({
  name: 'nesi-house-item',
  props: {
    item: {
      type: Object
    },
    minio: {
      type: String
    }
  },

  setup(props) {
    const store = useStore()
    const constant = reactive({
      areaList: deepCopy(AREA_MAP),
      situationList: deepCopy(SITUATIONS_MAP)
    })
    const house = ref(props.item)
    const minio = ref(props.minio)
    let imgages = []
    // const thirdSource = house.value.source ? getSource(house.value.source) : ''
    const sourceList = computed(() => {
      return store.getters.sourceList
    })
    let thirdSource = ''
    if (house.value.source) {
      const source = sourceList.value.find(
        (item) => item.source === house.value.source
      )
      thirdSource = source && source.src
    }
    let imgageSrc = ''
    if (house.value.source) {
      imgageSrc = house.value.imageUrl
    } else {
      if (house.value.houseOther) {
        imgages = JSON.parse(house.value.houseOther)
        imgageSrc = ref(minio.value + (imgages[0].src || imgages[0].smjsj))
      }
    }
    return {
      house,
      imgageSrc,
      numberFormat,
      constant,
      thirdSource
    }
  }
})
</script>

<style scoped lang="scss">
.small-wrapper {
  height: auto;
  width: 100%;
  padding: 15px 0;
  border-top: solid #e4e7ed 1px;

  &:hover {
    background: #f5f7fa;
    cursor: pointer;
  }

  .item {
    display: flex;
    width: 100%;
    .left {
      flex: 1;
      position: relative;
      padding-left: 10px;
      .image {
        width: 100%;
        height: 120px;
      }
      .validate {
        position: absolute;
        left: 10px;
        top: 0;
        color: white;
        font-size: 14px;
        background: #54d286;
        padding: 3px 5px;
        font-size: 13px;
        border: solid 1px #54d286;
      }
    }
    .center {
      width: 65%;
      padding-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .title {
        font-size: 16px;
        height: 20px;
        line-height: 20px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .tips-wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .tips {
          color: #909399;
          line-height: 22px;
          font-size: 13px;
        }
        .tags {
          .el-tag {
            margin-right: 20px;
          }
        }
      }

      .sale {
        display: flex;
        align-items: center;

        .total {
          color: #fa3534;
          font-size: 20px;
        }
        .price {
          color: #909399;
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
